import useApi from "../firebase/api/useApi";
import {useMemo} from "react";
import {keyBy} from "lodash-es";
import {Root} from "../firebase/api/ApiContext";

export function useRootInfo(databaseRoot: string): Root | undefined {
  const {roots} = useApi();
  const rootsById = useMemo(() => keyBy(roots, 'root'), [roots])

  return rootsById[databaseRoot];
}

import React, {FunctionComponent} from 'react';
import {ImportCustomerJobResult} from "../../firebase/api/ApiContext";
import {Button, Container} from "react-bootstrap";
import CannotImport from "./CannotImport";
import DataImported from "./DataImported";
import {useTranslation} from "react-i18next";

interface OwnProps {
  results: ImportCustomerJobResult
  handleImportAgain: () => void
}

type Props = OwnProps;

const Results: FunctionComponent<Props> = (props) => {
  const {results, handleImportAgain} = props;
  const {t} = useTranslation();

  let children;
  if (!results.isDataImported) {
    children = <CannotImport results={results}/>
  } else {
    children = <DataImported results={results}/>
  }

  return (
    <div className='mt-5'>
      <Container>
        {children}
        <div className='text-center'>
          <Button variant="outline-primary" onClick={handleImportAgain}>
            {t('newImportButton')}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Results;

import {AppTargetKey} from "./types";
import {Root} from "../firebase/api/ApiContext";
import {useMemo} from "react";

const NOME = 'name';
const LATITUDINE = 'lat';
const LONGITUDINE = 'lng';
const INDIRIZZO_COMPLETO = 'address';
const VIA = 'line1';
const CITY = 'city';
const PROVINCIA = 'state';
const CAP = 'zip';
const REFERENTE = 'referent';
const TELEFONO = 'phone';
const CELLULARE = 'cell';
const EMAIL = 'email';
// const COLORE = 'colore';
const NOTE = 'note';

export const targetKeys = [
  NOME,
  LATITUDINE,
  LONGITUDINE,
  INDIRIZZO_COMPLETO,
  VIA,
  CITY,
  CAP,
  PROVINCIA,
  EMAIL,
  REFERENTE,
  TELEFONO,
  CELLULARE,
  NOTE,
] as const;

export const translations = {
  [NOME]: 'Nome',
  [LATITUDINE]: 'Latitudine',
  [LONGITUDINE]: 'Longitudine',
  [INDIRIZZO_COMPLETO]: 'Indirizzo completo',
  [VIA]: 'Via',
  [CAP]: 'Cap',
  [CITY]: 'Città',
  [PROVINCIA]: 'Provincia',
  [REFERENTE]: 'Referente',
  [TELEFONO]: 'Telefono',
  [CELLULARE]: 'Cellulare',
  [EMAIL]: 'Email',
  [NOTE]: 'Note',
} as const;

export function getTranslation(key: AppTargetKey) {
  // @ts-ignore
  return translations[key] || key
}

export const requiredFields: {[key: string]: boolean} = {
  [NOME]: true,
  [INDIRIZZO_COMPLETO]: true,
} as const;

const synonyms = {
  'nome': NOME,
  'name': NOME,

  'latitudine': LATITUDINE,
  'lat': LATITUDINE,
  'longitudine': LONGITUDINE,
  'long': LONGITUDINE,
  'lng': LONGITUDINE,

  'indirizzo': INDIRIZZO_COMPLETO,
  'address': INDIRIZZO_COMPLETO,
  'indirizzo completo': INDIRIZZO_COMPLETO,
  'complete address': INDIRIZZO_COMPLETO,

  'via': VIA,
  'street': VIA,

  'cap': CAP,
  'codice postale': CAP,
  'zip': CAP,
  'zipcode': CAP,

  'località': CITY,
  'città': CITY,
  'city': CITY,

  'provincia': PROVINCIA,
  'state': PROVINCIA,

  'referente': REFERENTE,

  'telefono': TELEFONO,
  'phone': TELEFONO,

  'cellulare': CELLULARE,
  'mobile': CELLULARE,

  'email': EMAIL,

  'note': NOTE,
  'notes': NOTE,
} as const;

const threshold = 0;

export const usePreFillAlgorithm = (rootInfo?: Root) => {
  return useMemo(() => {
    if (!rootInfo) {
      return {
        threshold,
        synonyms
      };
    }

    const newSynonyms: any = {...synonyms};
    const customKeys = Object.keys(rootInfo.customField || {});
    for (const key of customKeys) {
      const name = rootInfo.customField[key].name;
      newSynonyms[name] = key;
      newSynonyms[name.toLowerCase()] = key;
    }

    console.log(newSynonyms);

    return {
      threshold,
      synonyms: newSynonyms
    };
  }, [rootInfo])
}

import React from "react";

export interface Root {
  name: string
  root: string
  geocodingThreshold: number
  customField: {[id: string]: CustomField}
}

export interface BaseCustomField {
  name: string
}

export interface MultipleChoiceCustomField extends BaseCustomField {
  type: "multipleChoice"
  values: {[id: string]: string}
}

export interface SingleChoiceCustomField extends BaseCustomField {
  type: "singleChoice"
  values: {[id: string]: string}
}

export interface TextFieldCustomField extends BaseCustomField {
  type: "textField"
}

export type CustomField = MultipleChoiceCustomField | TextFieldCustomField | SingleChoiceCustomField;

export interface ApiContextValue {
  roots?: Root[],
  startImportCustomersJob: (root: string, mappedSheet: CustomerToImport[], color: CustomerColor, keyMap: { [key: string]: string }) => Promise<ImportCustomerJobResult>;
  importProgressPercent: number
}

export interface ImportCustomerJobSuccessResult {
  isDataImported: true
  importedRows: Customer[]
  errorsRows: {err: number, customer: Partial<Customer>}[]
  outOfGeoCodingSlot: boolean
  serverResponse: ImportCustomerResponse
  root: Root
}

export interface ImportCustomerJobErrorResult {
  isDataImported: false
  error?: Error
}

export type ImportCustomerJobResult = ImportCustomerJobSuccessResult | ImportCustomerJobErrorResult

export type CustomerColor = 'rosso' | 'verde' | 'blu' | 'viola' | 'grigio' | 'marrone' | 'nero'

export interface Customer {
  name: string
  address: string
  lat: number
  lng: number
  note?: string
  phone?: string
  cell?: string
  referent?: string
  email?: string
}

export interface CustomerToImport {
  name: Customer['name']
  address: Customer['address']
  lat?: Customer['lat']
  lng?: Customer['lng']
  note?: Customer['note']
  phone?: Customer['phone']
  cell?: Customer['cell']
  referent?: Customer['referent']
  email?: Customer['email']
  customField: {
    [customField: string]: string
  }
}

export type ImportCustomerResponse = ImportCustomerSuccessResponse | ImportCustomerErrorResponse;

export interface ImportCustomerSuccessResponse {
  code: 'OK'
  total: number
  added: number
  skipped: number
}

export interface ImportCustomerErrorResponse {
  code: 'KO'
  err: number
  param0?: string
  param1?: string
}

// @ts-ignore
const ApiContext = React.createContext<ApiContextValue>({});

export default ApiContext;

import {KeyMapper} from "../lib/xlsx-importer/types";
import {targetKeys} from "./importer-settings";

type T = typeof targetKeys;

export function getAddressSelectionType(keyMap: KeyMapper<T[number]> | undefined): 'FULL' | 'MULTIPLE' {
  if (!keyMap) {
    return 'FULL'
  }

  if (keyMap.line1 || keyMap.city || keyMap.zip || keyMap.state) {
    return 'MULTIPLE'
  }

  return 'FULL'
}

import React, {FunctionComponent} from 'react';
import {Form, FormControlProps} from 'react-bootstrap';
import {CustomerColor} from "../firebase/api/ApiContext";
import {useTranslation} from "react-i18next";

interface OwnProps {
  value: FormControlProps['value']
  onChange: FormControlProps['onChange']
}

type Props = OwnProps;

const colors: readonly CustomerColor[] = ['rosso', 'verde', 'blu', 'viola', 'grigio', 'marrone', 'nero'] as const;

const SelectColor: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <Form.Control
      as="select"
      value={props.value}
      onChange={props.onChange}
    >
      {colors.map(color => (
        <option key={color} value={color}>{t(color)}</option>
      ))}
    </Form.Control>
  );
};

export default SelectColor;

import React, {FunctionComponent, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation, Trans} from "react-i18next";

interface OwnProps {
}

type Props = OwnProps;

const FirstTimeModal: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(true);

  function hide() {
    setOpen(false);
  }

  return (
    <Modal show={isOpen} onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>{t('firstTimeModalTitle')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Trans i18nKey="firstTimeModalBody">
          0
          <a href={t('terminiCondizioniUrl')}>termini e condizioni</a>
          2
          <a href={t('privacyPolicyUrl')}>privacy policy</a>
        </Trans>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={hide}>
          {t('firstTimeModalButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FirstTimeModal;

export function readFile(targetFile: File): Promise<any> {
  const reader = new window.FileReader();

  return new Promise(((resolve, reject) => {
    reader.onload = (e: any) => {
      resolve(e.target.result)
    };
    reader.readAsBinaryString(targetFile)
  }));
}

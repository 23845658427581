import {CustomerToImport, CustomField, Root} from "./ApiContext";
import {invert, mapValues} from 'lodash-es';

export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ValidationError";
  }
}

/**
 * Per il customField di tipo "*Choice" trasforma i valori -leggibili- in chiavi che sa riconoscere il backend.
 * @param customField - CustomField con la chiave "values" invertita dalla funzione `getUsefulCustomFields`
 * @param value - Valore da trasformare
 */
function transformValue(customField: CustomField, value: any) {
  if (value == null) {
    throw Error(`${customField.name} non è definito.`)
  }
  switch (customField.type) {
    case 'textField':
      if (typeof value !== 'string') {
        throw new ValidationError(`Il campo "${customField.name}" non è una stringa valida.`);
      }
      return value;

    case 'singleChoice':
    case 'multipleChoice':
      if (customField.values[value] == null) {
        throw new ValidationError(`Il campo "${customField.name}" non prevede l’opzione "${value}".`);
      }
      return customField.values[value]

    // case 'multipleChoice':
    //   const values = value.split(',').map((x: string) => x.trim());
    //   for (const value of values) {
    //     if (customField.values[value] == null) {
    //       throw new ValidationError(`Il campo "${customField.name}" non prevede l’opzione "${value}".`);
    //     }
    //   }
    //   return values.map((value: string) => customField.values[value]);
  }
}

/**
 * Inverte chiave e valore nell'oggetto "values" utile per la funzione `transformValue`.
 * @param root
 */
function getUsefulCustomFields(root: Root) {
  return mapValues(root.customField, customField => {
    if (customField.type === 'textField') {
      return customField;
    } else if (customField.type === 'singleChoice' || customField.type === 'multipleChoice') {
      return {
        ...customField,
        values: invert(customField.values)
      }
    }
  }) as {[id: string]: CustomField}
}

/**
 * Trasforma mappedSheet formattando il campo customField come vuole il backend
 * @param root
 * @param keyMap - Mappatura delle chiavi (id: nome colonna)
 * @param mappedSheet
 */
function transformCustomField(root: Root, keyMap: { [key: string]: string }, mappedSheet: CustomerToImport[]) {
  const customFieldsPopulated = (
    Object.keys(keyMap)
      .filter(x => !!root.customField[x])
      .filter(x => !!keyMap[x])
  );
  // const customFieldsPopulated = Object.keys(root.customField)

  const usefulCustomFields = getUsefulCustomFields(root);

  for (let rowIndex = 0; rowIndex < mappedSheet.length; rowIndex++){
    const customerToImport = mappedSheet[rowIndex];
    const customerCustomField: { [p: string]: string } = {}
    for (const customFieldKey of customFieldsPopulated) {
      const customFieldType = usefulCustomFields[customFieldKey];
      const value = customerToImport.customField[customFieldKey];
      try {
        customerCustomField[customFieldKey] = transformValue(customFieldType, value);
      } catch (e) {
        const nextMessage = `Controllare la riga ${rowIndex + 2}.`;
        if (e instanceof ValidationError) {
          throw new ValidationError(`${e.message} ${nextMessage}`);
        }
        console.error(e);
        throw new Error(`${e.message} ${nextMessage}`);
      }
    }
    customerToImport.customField = customerCustomField;
  }
}

export default transformCustomField

import React, {FunctionComponent, useState} from 'react';
import LoginModal from "./LoginModal";
import {Button, ButtonProps} from "react-bootstrap";

interface OwnProps {
  variant?: ButtonProps['variant']
  children?: string
}

type Props = OwnProps;

const LoginUserButton: FunctionComponent<Props> = (props) => {
  const [modal, setModal] = useState<boolean>(false);

  function openModal() {
    setModal(true);
  }

  function closeModal() {
    setModal(false);
  }

  return (
    <>
      {modal && <LoginModal onHide={closeModal}/>}
      <Button variant={props.variant} onClick={openModal}>
        {props.children}
      </Button>
    </>
  );
};

LoginUserButton.defaultProps = {
  variant: 'outline-primary',
};

export default LoginUserButton;

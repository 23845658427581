import React from 'react';
import { parseJsonSheet, preFillKeyMapObject, readFile, readSheetFileData, readSheetFromString } from './utils';
import { ExistingKey, KeyMapper, MappedSheet, Sheet, TargetKeys } from './types';

type XlsxImporterHandlers<T extends TargetKeys> = {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onToKeyChange: (key: T[number], value: string) => void;
  onSubmit: () => void;
}

export type ChildrenProps<T extends TargetKeys> = State<T> & XlsxImporterHandlers<T>

type Props<T extends TargetKeys> = {
  targetKeys: T,
  onCompleteParse: (mappedSheet: MappedSheet<T[number]>, bag: State<T>) => void,
  children: (state: ChildrenProps<T>) => React.ReactNode
  preFillAlgorithm?: {
    threshold?: number
    synonyms?: { [synonymous: string]: T[number] }
  }
}

type State<T extends TargetKeys> = {
  keyMapper: KeyMapper<T[number]>,
  fileName: string,
  existingKeys: ExistingKey[]
  sheet: Sheet,
}

class XlsxImporterForm<T extends TargetKeys> extends React.PureComponent<Props<T>, State<T>> {
  static defaultProps = {
    preFillAlgorithm: {
      threshold: 0.5,
      synonyms: null
    }
  };

  constructor (props: Props<T>) {
    super(props);

    this.state = {
      sheet: [],
      keyMapper: {},
      fileName: '',
      existingKeys: []
    };
  }

  initValues () {
    this.setState({
      sheet: [],
      keyMapper: {},
      fileName: '',
      existingKeys: []
    });
  }

  preFillKeyMapObject (existingKeys: ExistingKey[]) {
    const { targetKeys, preFillAlgorithm } = this.props;

    // @ts-ignore
    const keyMapper: KeyMapper<T[number]> = preFillKeyMapObject(existingKeys, targetKeys, preFillAlgorithm);
    this.setState({ keyMapper });
  }

  onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.initValues();

    /*  wire up file reader  */
    if (event.currentTarget.files?.length !== 1) {
      throw Error('Cannot use multiple files');
    }

    this.loadSheetFromFile(event.currentTarget.files[0]).catch(console.error);
  };

  onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;

    try {
      const { sheet, keys } = readSheetFromString(value);

      this.setState({
        sheet: sheet,
        existingKeys: keys
      });

      this.preFillKeyMapObject(keys);
    } catch (e) {
      console.warn(e);
    }

    console.log(value);
  };

  async loadSheetFromFile (file: File) {
    const result = await readFile(file);

    const fileArray = file.name.split('.');
    const fileExt = fileArray[fileArray.length - 1];
    if (!/xlsx?|csv/.test(fileExt.toLowerCase())) {
      window.alert('Formato file non valido!'); // TODO remove
      return;
    }

    const { sheet, keys } = readSheetFileData(result);
    this.setState({
      sheet: sheet,
      existingKeys: keys,
      fileName: file.name
    });

    this.preFillKeyMapObject(keys);
  }

  uploadJsonSheet = () => {
    const parsedJson: MappedSheet<T[number]> = parseJsonSheet(this.state.sheet, this.state.keyMapper);
    this.props.onCompleteParse(parsedJson, this.state);
  };

  onToKeyChange = (key: T[number], value: string) => {
    const keyMapObject = {
      ...this.state.keyMapper,
      [key]: value
    };
    this.setState({ keyMapper: keyMapObject });
  };

  render () {
    return this.props.children({
      ...this.state,
      onFileChange: this.onFileChange,
      onTextChange: this.onTextChange,
      onToKeyChange: this.onToKeyChange,
      onSubmit: this.uploadJsonSheet
    });
  }
}

export default XlsxImporterForm;

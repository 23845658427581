import React, {FunctionComponent, useState} from 'react';
import Importer from "./Importer";
import {ImportCustomerJobResult} from "../firebase/api/ApiContext";
import Results from "./Results";

interface OwnProps {
}

type Props = OwnProps;

const ImporterWithResults: FunctionComponent<Props> = (props) => {
  const [results, setResults] = useState<ImportCustomerJobResult>();

  function handleSuccess(data: ImportCustomerJobResult) {
    setResults(data);
  }

  function handleImportAgain() {
    setResults(undefined);
  }

  if (results) {
    return (
      <Results
        results={results}
        handleImportAgain={handleImportAgain}
      />
    )
  }

  return (
    <Importer
      onSuccess={handleSuccess}
    />
  );
};

export default ImporterWithResults;

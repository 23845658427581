import useApi from "../firebase/api/useApi";
import {ChangeEventHandler, useState} from "react";
import {CustomerColor, CustomerToImport, ImportCustomerJobResult} from "../firebase/api/ApiContext";
import {MappedSheet, MappedSheetRow} from "../lib/xlsx-importer/types";
import {targetKeys} from "./importer-settings";

type RowType = typeof targetKeys[number];

export function useImportSheet(
  buildMappedSheet: () => MappedSheet<RowType>,
  onSuccess: (data: ImportCustomerJobResult) => void,
  databaseRoot: string,
  keyMap: { [key: string]: string }
) {
  const {startImportCustomersJob, importProgressPercent} = useApi();
  const [color, setColor] = useState<CustomerColor>('verde');
  const isLoading = importProgressPercent < 100;

  const handleSubmit = () => {
    if (isLoading || !databaseRoot) {
      return;
    }

    const mappedSheet = buildMappedSheet();

    startImportCustomersJob(databaseRoot, sheetToCustomer(mappedSheet, keyMap), color, keyMap)
      .then((result) => {
        onSuccess(result);
      });
  };

  const handleColorChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    setColor(value as CustomerColor);
  };

  return {
    isLoading,
    loadingProgress: importProgressPercent,
    handleSubmit,
    color,
    handleColorChange
  };
}

function sheetToCustomer(mappedSheet: MappedSheetRow<RowType>[], keyMap: { [key: string]: string }): CustomerToImport[] {
  return mappedSheet.map(x => {
    const {lat, lng, phone, referent, note, name, email, address, cell, line1, zip, city, state, ...customField} = x;
    return {
      name: name,
      address: keyMap.address ? address : buildAddressFromComponents(x),
      lat: lat,
      lng: lng,
      note: note,
      phone: phone,
      cell: cell,
      referent: referent,
      email: email,
      customField,
    };
  }) as CustomerToImport[];
}

function buildAddressFromComponents(x: { line1?: string, zip?: string, city?: string, state?: string }) {
  return `${x.line1?.trim() || ''}, ${x.zip || ''} ${x.city || ''} ${x.state || ''}`
    .replace(/\s\s+/g, ' ')
    .trim();
}

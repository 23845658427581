import { ExistingKey, KeyMapper, PreFillAlgorithm, TargetKeys } from '../types';
import stringSimilarity from 'string-similarity';

export function preFillKeyMapObject<T extends TargetKeys> (
  existingKeys: ExistingKey[],
  targetKeys: T,
  preFillAlgorithm?: PreFillAlgorithm<T>
) {
  const keyMapObject: KeyMapper<T[number]> = {};
  const {
    threshold = 0,
    synonyms
  } = preFillAlgorithm || {};

  if (synonyms != null) {
    preFillWithSynonyms(synonyms, existingKeys, threshold, keyMapObject);
  } else {
    prefillWithKeys(targetKeys, existingKeys, threshold, keyMapObject);
  }

  return keyMapObject;
}

function preFillWithSynonyms<T extends TargetKeys> (
  synonyms: { [p: string]: T[number] },
  existingKeys: ExistingKey[],
  threshold: number,
  keyMapObject: KeyMapper<T[number]>
) {
  const strings = Object.keys(synonyms);

  const keyMapRatings: any = {};
  for (const existingKey of existingKeys) {
    const findBestMatch = stringSimilarity.findBestMatch(existingKey, strings);

    const rating = findBestMatch.bestMatch.rating;
    const targetKey: T[number] = synonyms[findBestMatch.bestMatch.target];
    if (rating > (keyMapRatings[targetKey] || threshold)) {
      keyMapRatings[targetKey] = rating;
      keyMapObject[targetKey] = existingKey;
    }
  }
  console.log(keyMapRatings);
}

function prefillWithKeys<T extends TargetKeys> (
  targetKeys: T,
  existingKeys: ExistingKey[],
  threshold: number,
  keyMapObject: KeyMapper<T[number]>
) {
  for (const key of targetKeys) {
    const findBestMatch = stringSimilarity.findBestMatch(key, existingKeys);
    if (findBestMatch.bestMatch.rating > threshold) {
      keyMapObject[(key as T[number])] = findBestMatch.bestMatch.target;
    }
  }
}

import xlsx from 'xlsx';
import { KeyMapper, MappedSheet, MappedSheetRow, Sheet, SheetRow, TargetKey } from '../types';

export function readSheetFileData (data: any) {
  const workbook = xlsx.read(data, { type: 'binary', raw: true });
  const selectedSheetName = workbook.SheetNames[0];
  const selectedSheet = workbook.Sheets[selectedSheetName];

  const sheet: Sheet = xlsx.utils.sheet_to_json(selectedSheet, { raw: true, defval: null });

  if (sheet.length === 0) {
    throw Error('File is empty');
  }

  const keys = Object.keys(sheet[0]);
  return { sheet, keys };
}

export function readSheetFromString (data: string) {
  const lines = data.split(/\r?\n/)
    .filter(s => s.length > 0);

  const keys = lines[0].split('\t');

  const sheet: Sheet = lines.slice(1).map((line) => {
    const row: SheetRow = {};
    line.split('\t')
      .forEach((column, i) => {
        row[keys[i]] = column;
      });
    return row;
  });

  return {
    sheet,
    keys
  };
}

export function parseJsonSheet<T extends TargetKey> (json: Sheet, keyMapper: KeyMapper<T>): MappedSheet<T> {
  if (!keyMapper || !json) {
    throw Error('Error while parsing');
  }
  return json.map((fromObj) => {
    const toObj: MappedSheetRow<T> = {};

    for (const key of Object.keys(keyMapper)) {
      // @ts-ignore
      toObj[key] = fromObj[keyMapper[key]];
    }
    return toObj;
  });
}

import React from 'react';
import './App.css';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import Navbar from "./Navbar";
import Importer from "./Importer";
import Footer from "./Footer";
import ApiProvider from "./firebase/api/ApiProvider";
import FirstTimeModal from './components/FirstTimeModal';
import { hot } from 'react-hot-loader/root'

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ApiProvider>
        <>
          <FirstTimeModal/>
          <Navbar/>
          <Importer/>
          <Footer/>
        </>
      </ApiProvider>
    </I18nextProvider>
  );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App;

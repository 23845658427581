import React, {FunctionComponent} from 'react';
import {Button, ButtonProps} from "react-bootstrap";
import {ImportCustomerJobSuccessResult} from "../../firebase/api/ApiContext";
import XLSX from 'xlsx';
import {identity, pickBy} from "lodash-es";

interface OwnProps extends ButtonProps {
  rows: ImportCustomerJobSuccessResult['errorsRows']
}

type Props = OwnProps;

const ExportCsvButton: FunctionComponent<Props> = (props) => {
  const {rows} = props;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    download('rows-not-imported.csv', generateCsv(rows))
  };

  return (
    <Button {...props} onClick={handleClick}/>
  );
};


function download(filename: string, text: string) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element)
}


function generateCsv(rows: ImportCustomerJobSuccessResult['errorsRows']) {
  const workBook = XLSX.utils.json_to_sheet(
    rows.map(x => ({
      ...pickBy(x.customer, identity),
      error: x.err
    }))
  );
   return XLSX.utils.sheet_to_csv(workBook);
}

export default ExportCsvButton;

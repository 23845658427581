import useApi from "../firebase/api/useApi";
import {ChangeEventHandler, useEffect, useState} from "react";
import {useRootInfo} from "./useRootInfo";

export function useDatabaseRootState() {
  const {roots} = useApi();
  const [databaseRoot, setDatabaseRoot] = useState<string>('');

  useEffect(() => {
    if (roots && roots.length === 1) {
      setDatabaseRoot(roots[0].root);
    } else {
      setDatabaseRoot('');
    }
  }, [roots]);

  const handleDatabaseRootChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    setDatabaseRoot(value);
  };

  const rootInfo = useRootInfo(databaseRoot);

  return {
    roots,
    databaseRoot,
    handleDatabaseRootChange,
    rootInfo
  }
}

import {useEffect, useState} from "react";
import firebase, {User} from "firebase";

export function useLoggedUser() {
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(
      (user) => setUser(user)
    );
  });
  return user;
}

import {useEffect, useState} from 'react';
import {parseJsonSheet, preFillKeyMapObject, readFile, readSheetFileData, readSheetFromString} from './utils';
import {KeyMapper, MappedSheet, PreFillAlgorithm, TargetKeys} from './types';
import {XlsxImportHook, XlsxImportResult} from "./useXlsxImport-types";

type Options<T extends TargetKeys> = {
  preFillAlgorithm: PreFillAlgorithm<T>,
  afterPreFill?: (keyMap: KeyMapper<T[number]>) => KeyMapper<T[number]>
  // transform: {[key in T[number]]: (value: string) => string}
}

export function useXlsxImport(): XlsxImportHook {
  const [sheetState, setSheetState] = useState<any>({});

  function parseFile(file: File) {
    loadSheetFromFile(file)
      .then(sheetState => setSheetState(sheetState))
      .catch(error => setSheetState({error}));
  }

  function parseString(data: string) {
    try {
      const sheetState = readSheetFromString(data);
      setSheetState(sheetState);
    } catch (error) {
      setSheetState({error});
    }
  }

  return {
    parseFile,
    parseString,
    result: sheetState,
  };
}

export function useSheetMapper<T extends TargetKeys>(xlsxImportResult: XlsxImportResult, targetKeys: T, options?: Options<T>) {
  const {preFillAlgorithm, afterPreFill} = options || {};
  const [keyMap, setKeyMap] = useState<KeyMapper<T[number]>>();

  useEffect(() => {
    if (xlsxImportResult.keys) {
      let keyMap = preFillKeyMapObject(xlsxImportResult.keys, targetKeys, preFillAlgorithm);
      if (afterPreFill) keyMap = afterPreFill(keyMap);
      setKeyMap(keyMap);
    }
  }, [xlsxImportResult.keys, targetKeys, preFillAlgorithm, afterPreFill]);

  function setKeyMapField(key: T[number], value: string) {
    setKeyMap(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  function buildMappedSheet() {
    if (!keyMap) throw Error('keyMap is undefined');
    if (!xlsxImportResult) throw Error('xlsxImportResult is undefined');
    if (!xlsxImportResult.sheet) throw Error('xlsxImportResult.sheet is undefined');

    const parsedJson: MappedSheet<T[number]> = parseJsonSheet(xlsxImportResult.sheet, keyMap);
    return parsedJson;
  }

  return {
    keyMap,
    setKeyMap,
    setKeyMapField,
    buildMappedSheet
  };
}

async function loadSheetFromFile(file: File) {
  const result = await readFile(file);

  const fileArray = file.name.split('.');
  const fileExt = fileArray[fileArray.length - 1];
  if (!/xlsx?|csv/.test(fileExt.toLowerCase())) {
    throw Error('File format is not valid');
  }

  const {sheet, keys} = readSheetFileData(result);
  return {
    sheet,
    keys
  };
}

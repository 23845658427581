import React, {FunctionComponent, ReactEventHandler, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import firebase from "firebase";
import {useTranslation} from "react-i18next";

interface OwnProps {
  onHide: () => void
}

type Props = OwnProps;

// DOC: https://firebase.google.com/docs/reference/node/firebase.auth.Auth#sign-inwith-email-and-password
const errorCodeToMessage = {
  "auth/invalid-email": "L'indirizzo email non è valido.",
  "auth/user-disabled": "L'utente associato a questa email è stato disabilitato.",
  "auth/user-not-found": "Non è stato trovato nessun utente con questa email.",
  "auth/wrong-password": "La password per questo utente non è corretta, o l'utente corrispondente a questa email non ha una password impostata.",
};

const LoginModal: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>();

  const handleSignIn: ReactEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors('');

    const elements: any = event.currentTarget.elements;
    const email: string = elements['email'].value;
    const password: string = elements['password'].value;

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(
      () => firebase.auth().signInWithEmailAndPassword(email, password)
    ).catch(error => {
        setLoading(false);

        const errorCode = error.code;
        const errorMessage = error.message;
        // @ts-ignore
        setErrors(errorCodeToMessage[errorCode] || errorMessage);
      });
  };

  return (
    <Modal show={true} onHide={props.onHide}>
      <Form onSubmit={handleSignIn}>
        <Modal.Header closeButton>
          <Modal.Title>{t('accediAGentmo')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>{t('email')}</Form.Label>
            <Form.Control name='email' type="email" placeholder={t('emailPlaceholder')}/>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>{t('password')}</Form.Label>
            <Form.Control name='password' type="password" placeholder={t('passwordPlaceholder')}/>
          </Form.Group>

          {errors && <p className='text-danger'>{errors}</p>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>{t('chiudi')}</Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {t('accedi')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default LoginModal;

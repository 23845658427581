import React, {FunctionComponent} from 'react';
import {useLoggedUser} from "./useLoggedUser";
import {Dropdown} from "react-bootstrap";
import firebase from "firebase";
import LoginUserButton from "./LoginUserButton";
import useApi from "./api/useApi";
import {useTranslation} from "react-i18next";

interface OwnProps {
}

type Props = OwnProps;

const LoggedUserButton: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation();
  const {importProgressPercent} = useApi();
  const user = useLoggedUser();

  function signOut() {
    firebase.auth().signOut();
  }

  if (user) {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          disabled={importProgressPercent < 100}
        >
          {user && (user.displayName || user.email)}
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight>
          <Dropdown.Item onClick={signOut}>{t('logout')}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  } else if (user === undefined) {
    return <></>
  }

  return (
    <LoginUserButton>{t('accedi')}</LoginUserButton>
  );
};

export default LoggedUserButton;

import {AppTargetKey} from "./types";

interface SetKeyMapField {
  (key: AppTargetKey[number], value: string): void
}

export function wrapSetKeyMapField(f: SetKeyMapField): SetKeyMapField {
  return function (key, value) {
    console.log('wrapSetKeyMapField', key, value);
    if (key === 'address') {
      f('line1', '');
      f('city', '');
      f('zip', '');
      f('state', '');
    }
    if (['line1', 'city', 'zip', 'state'].indexOf(key) > -1) {
      f('address', '')
    }
    f(key, value)
  }
}

import React, {FunctionComponent} from 'react';
import {Alert} from "react-bootstrap";
import {ImportCustomerJobErrorResult} from "../../firebase/api/ApiContext";
import {Trans} from "react-i18next";
import {ValidationError} from "../../firebase/api/transformCustomField";

interface OwnProps {
  results: ImportCustomerJobErrorResult
}

type Props = OwnProps;

const CannotImport: FunctionComponent<Props> = (props) => {
  const {results} = props;

  return (results.error instanceof ValidationError)
    ? (
      <Alert variant='danger'>
        {results.error?.message}
      </Alert>
    )
    : (
      <Alert variant='danger'>
        <Trans i18nKey='resultCriticalError'>
          A causa di un errore grave non sono riuscito ad importare i dati.
          <br/>
          Errore: {{message: results.error?.message}}
        </Trans>
      </Alert>
    );
};

export default CannotImport;

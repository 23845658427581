import React, {FunctionComponent} from 'react';
import {Alert, Form, OverlayTrigger, Popover} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import {Root} from "../firebase/api/ApiContext";

interface OwnProps {
  rootInfo: Root | undefined
  handleTextChange: React.ChangeEventHandler<HTMLInputElement>
  isLoading: boolean
}

type Props = OwnProps;

const ImporterForm: FunctionComponent<Props> = (props) => {
  const {rootInfo, handleTextChange, isLoading} = props;
  const {t} = useTranslation();

  return (
    <>
      {/*        <div className="input-group mb-3">
          <div className="custom-file">
            <input type="file" className="custom-file-input" id="inputGroupFile01" onChange={handleFileChange} disabled={isLoading}/>
            <label className="custom-file-label" htmlFor="inputGroupFile01">
              {fileName || 'Seleziona un file'}
            </label>
          </div>
        </div>

        <hr/>*/}

      <Form.Group controlId="exampleForm.ControlTextarea1">
        {rootInfo?.geocodingThreshold != null && (
          <div className="float-right">
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement='top'
              overlay={
                <Popover id={`popover-geocoding-threshold`}>
                  <Popover.Content>
                    {t('importerGeocodingThresholdsPopover')}
                  </Popover.Content>
                </Popover>
              }
            >
                <span className='text-dotted'>
                 <Trans i18nKey='importerGeocodingThresholds'>
                    {{number: rootInfo.geocodingThreshold}} geolocalizzazioni disponibili
                  </Trans>
                </span>
            </OverlayTrigger>
          </div>
        )}
        {/*<Form.Label>{t('Oppure incolla i tuoi dati di posizione sotto per mapparli:')}</Form.Label>*/}
        <Form.Label>{t('importerTextareaLabel')}</Form.Label>
        <Form.Control as="textarea" rows="6" onChange={handleTextChange} disabled={isLoading}/>
      </Form.Group>

      <Alert variant='info' className='mt-2'>
        {t('importerFormHelpMessage')}
      </Alert>
    </>
  );
};

export default ImporterForm;

import {KeyMapper} from "../lib/xlsx-importer/types";
import {targetKeys} from "./importer-settings";

const error1 = "Selezionare una colonna per l'indirizzo completo o per i singoli elementi dell'indirizzo";
const error2 = `Selezionare una colonna per l'indirizzo completo o per gli altri elementi dell'indirizzo`;
const error3 = `Selezionare una colonna`;

type T = typeof targetKeys;

export function validateKeyMap(keyMap: KeyMapper<T[number]> | undefined) {
  if (!keyMap) {
    return {
      errors: {},
      isValid: false
    }
  }

  const errors: any = {};

  if (keyMap.address) {
    if (keyMap.line1) errors.line1 = error1;
    if (keyMap.city) errors.city = error1;
    if (keyMap.state) errors.state = error1;
    if (keyMap.zip) errors.zip = error1;
  } else if (!keyMap.line1 && !keyMap.city && !keyMap.state && !keyMap.zip) {
    errors.address = error2;
  } else {
    if (!keyMap.line1) errors.line1 = error3;
    if (!keyMap.city) errors.city = error3;
  }

  if (!keyMap.name) errors.name = error3;

  if (keyMap.lat && !keyMap.lng) errors.lng = error3;
  if (keyMap.lng && !keyMap.lat) errors.lat = error3;

  const isValid: boolean = Object.values(errors).every(x => !x);

  return {errors, isValid}
}

import React, { FunctionComponent } from 'react';
import {Container} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const address = "Pumpkin Lab s.r.l. - via della Tecnica 2, 04011 Aprilia (LT) - C.F. / P.IVA 02993440599";

interface OwnProps {}

type Props = OwnProps;

const Footer: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <footer className='text-light bg-secondary p-4 app-footer'>
      <Container>
        {address}
        {" - "}
        <a className='text-white' href={t('terminiCondizioniUrl')}>
          Termini e Condizioni
        </a>
        {" - "}
        <a className='text-white' href={t('privacyPolicyUrl')}>
          Privacy Policy
        </a>
      </Container>
    </footer>
  );
};

export default Footer;

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// TODO Dopo aver rimesso il caricamento da file bisogna modificare
// importerTextareaLabel: "Oppure incolla i tuoi dati di posizione sotto per mapparli:"
import resources from './i18n.json'

/*
 * FOR FOOTER STRINGS
 * See src/components/Footer/Footer.tsx
 */

/*
 * FOR LOGIN ERROR STRINGS
 * See src/components/firebase/LoginModal.tsx
 */

/*
 * FOR "BETA" AFTER LOGO
 * See src/index.scss (rule: .app-logo::after)
 */

/*
 * FOR FIELDS
 * See src/Importer/importer-settings.ts
 */



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "it",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    debug: (process.env.NODE_ENV === 'development')
  });

export default i18n;

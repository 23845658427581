import React, {FunctionComponent} from 'react';
import {Form, FormControlProps} from 'react-bootstrap';
import {Root} from "../firebase/api/ApiContext";

interface OwnProps {
  value: FormControlProps['value']
  onChange: FormControlProps['onChange']
  roots?: Root[]
  disabled?: boolean
}

type Props = OwnProps;

const SelectRoot: FunctionComponent<Props> = (props) => {
  if (!props.roots) {
    return (
      <Form.Control as="select">
        <option>{'Loading…'}</option>
      </Form.Control>
    )
  }

  return (
    <Form.Control
      as="select"
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      <option value='' disabled>Seleziona...</option>
      {props.roots.map(root => (
        <option key={root.root} value={root.root}>{root.name}</option>
      ))}
    </Form.Control>
  );
};

export default SelectRoot;

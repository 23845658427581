import React, {ChangeEventHandler, FunctionComponent} from 'react';
import {Alert, Button, Col, Form, ProgressBar, Row, Table} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import SelectRoot from "./SelectRoot";
import SelectColor from "./SelectColor";
import LoginUserButton from "../firebase/LoginUserButton";
import {XlsxImportResultSuccess} from "../lib/xlsx-importer/useXlsxImport-types";
import {useLoggedUser} from "../firebase/useLoggedUser";
import {CustomerColor, Root} from "../firebase/api/ApiContext";
import {validateKeyMap} from "./validateKeyMap";
import {getAddressSelectionType} from "./getAddressSelectionType";
import {wrapSetKeyMapField} from "./wrapSetKeyMapField";
import {KeyMapper} from "../lib/xlsx-importer/types";
import {requiredFields} from "./importer-settings";

interface OwnProps {
  isLoading: boolean
  result: XlsxImportResultSuccess

  databaseRoot: string
  handleDatabaseRootChange: ChangeEventHandler<HTMLInputElement>
  roots: Root[] | undefined

  targetKeys: string[]
  setKeyMapField: (key: string, value: string) => void
  keyMap: KeyMapper<string> | undefined
  getTranslation: (key: string) => string

  color: CustomerColor
  handleColorChange: ChangeEventHandler<HTMLInputElement>

  loadingProgress: number
  handleSubmit: () => void
}

type Props = OwnProps;

const ImporterColumnsMapper: FunctionComponent<Props> = (props) => {
  const {
    isLoading,
    result,
    databaseRoot,
    handleDatabaseRootChange,
    roots,
    targetKeys,
    setKeyMapField,
    keyMap,
    getTranslation,
    color,
    handleColorChange,
    loadingProgress,
    handleSubmit
  } = props;

  const {t} = useTranslation();
  const user = useLoggedUser();

  const rootsSelectorClassName = roots?.length === 1 ? 'd-none' : '';

  const {errors, isValid} = validateKeyMap(keyMap);
  const addressSelectionType = getAddressSelectionType(keyMap);
  const setKeyMapFieldWrapped = wrapSetKeyMapField(setKeyMapField);

  return (
    <div>
      <hr/>
      <Alert variant='success'>
        <b>
          <Trans t={t} i18nKey='importerSuccessMessageTitle'>
            Fatto: {{columns: result.keys.length}} colonne, {{rows: result.sheet.length}} righe.
          </Trans>
        </b>
        <br/>{t('importerSuccessMessageText')}
      </Alert>
      <div>
        <h3>{t('importerSuccessTitle')}</h3>
        <p>
          {t('importerSuccessText')}
        </p>
      </div>

      <div className='mt-3'>
        {user && (
          <Row>
            <Col xl={{span: 6, offset: 3}} lg={{span: 8, offset: 2}}>
              <Alert variant='info' className={`mt-2 mb-5 ${rootsSelectorClassName}`}>
                <div className='d-flex flex-column align-items-center'>
                  <div className='mb-2 font-weight-bold'>
                    {t('importerSelectDatabaseLabel')}
                  </div>
                  <SelectRoot
                    value={databaseRoot}
                    onChange={handleDatabaseRootChange}
                    roots={roots}
                    disabled={isLoading}
                  />
                </div>
              </Alert>
            </Col>
          </Row>
        )}
      </div>

      <Row>
        <Col xl={{span: 6, offset: 3}} lg={{span: 8, offset: 2}}>
          <Table striped bordered hover size='sm' style={{'tableLayout': 'fixed'}}>
            <tbody>
            {targetKeys.map(fromKey => {
              const thFontWeight = (addressSelectionType === 'MULTIPLE' && fromKey === 'address') ||
                (addressSelectionType === 'FULL' && ['line1', 'city', 'zip', 'state'].indexOf(fromKey) > -1);

              return (
                <tr key={fromKey}>
                  <th
                    className='text-right align-middle'
                    style={{
                      fontWeight: thFontWeight ? 'inherit' : 'bold',
                      opacity: thFontWeight ? 0.67 : 1
                    }}
                  >
                    {requiredFields[fromKey] && '*'}{getTranslation(fromKey)}:
                  </th>
                  <Form.Group as={'td'} md="3">
                    <Form.Control
                      as="select"
                      size='sm'
                      value={keyMap?.[fromKey]}
                      onChange={event => {
                        setKeyMapFieldWrapped(fromKey, event.currentTarget.value)
                      }}
                      isInvalid={!!errors[fromKey]}
                      disabled={isLoading}
                    >
                      <option value=''>-</option>
                      {result.keys.map(sheetKey => (
                        <option key={sheetKey} value={sheetKey}>{sheetKey}</option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors[fromKey]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <div className='mt-3'>
        {user ? (
          <Row>
            <Col xl={{span: 6, offset: 3}} lg={{span: 8, offset: 2}}>
              <Alert variant='info'>
                <div className='d-flex flex-column align-items-center'>
                  <div className='mb-2 font-weight-bold'>
                    {t('importerSelectRootLabel')}
                  </div>
                  <SelectColor
                    value={color}
                    onChange={handleColorChange}
                  />
                </div>
              </Alert>
              {isLoading && (
                <ProgressBar now={loadingProgress} label={`${Math.round(loadingProgress || 0)}%`}/>
              )}
              <div className='text-center'>
                <Button
                  className='mt-3'
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isLoading || !databaseRoot || !isValid}
                >
                  {isLoading ? t('importerSubmitButtonLoading') : t('importerSubmitButton')}
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <div className='d-flex flex-row justify-content-center align-items-baseline'>
            <div className='mr-2'>{t('importerYouMustLoginPreButton')}</div>
            <LoginUserButton variant='primary'>{t('importerYouMustLoginButton')}</LoginUserButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImporterColumnsMapper;

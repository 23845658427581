import React, {FunctionComponent} from 'react';
import {Container} from "react-bootstrap";
import LoggedUserButton from "../firebase/LoggedUserButton";
import {useTranslation} from "react-i18next";

interface OwnProps {
}

type Props = OwnProps;

const Navbar: FunctionComponent<Props> = (props) => {
  const {t} = useTranslation();

  return (
    <header>
      <Container>
        <div className='d-flex flex-row align-items-center'>
          <div className='mt-3'>
            <h1 className='app-logo'>{t('logo')}</h1>
            <p className='text-primary'>{t('logoDescription')}</p>
          </div>
          <div className='ml-auto'>
            <LoggedUserButton/>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Navbar;

import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSheetMapper, useXlsxImport} from "../lib/xlsx-importer";
import './Importer.css';
import {getTranslation as getTranslationBase, targetKeys as baseTargetKeys, usePreFillAlgorithm} from "./importer-settings";
import {Container} from "react-bootstrap";
import {useImportSheet} from "./useImportSheet";
import {ImportCustomerJobResult} from "../firebase/api/ApiContext";
import {KeyMapper} from "../lib/xlsx-importer/types";
import {useDatabaseRootState} from "./useDatabaseRootState";
import {AppTargetKey} from "./types";
import ImporterForm from "./ImporterForm";
import ImporterColumnsMapper from "./ImporterColumnsMapper";

interface OwnProps {
  onSuccess: (data: ImportCustomerJobResult) => void
}

type Props = OwnProps;

function afterPreFill(keyMap: KeyMapper<AppTargetKey>) {
  if (keyMap.address) {
    if (keyMap.line1) keyMap.line1 = undefined;
    if (keyMap.city) keyMap.city = undefined;
    if (keyMap.state) keyMap.state = undefined;
    if (keyMap.zip) keyMap.zip = undefined;
  }
  if (!keyMap.lat) {
    keyMap.lng = undefined
  }
  if (!keyMap.lng) {
    keyMap.lat = undefined
  }
  return keyMap;
}

const Importer: FunctionComponent<Props> = (props) => {
  const [fileName, setFileName] = useState<string>();
  const {parseFile, parseString, result} = useXlsxImport();

  const {
    roots,
    databaseRoot,
    handleDatabaseRootChange,
    rootInfo
  } = useDatabaseRootState()

  const targetKeys = useMemo(() => (
    [...baseTargetKeys, ...Object.keys(rootInfo?.customField || {})]
  ), [rootInfo])

  const getTranslation = useCallback((key: string) => {
    if (rootInfo?.customField[key] != null) {
      return rootInfo?.customField[key].name;
    }
    return getTranslationBase(key);
  }, [rootInfo])

  const preFillAlgorithm = usePreFillAlgorithm(rootInfo);

  const {setKeyMapField, buildMappedSheet, keyMap} = useSheetMapper(result, targetKeys, {
    preFillAlgorithm,
    afterPreFill
  });

  const {
    isLoading,
    loadingProgress,
    handleSubmit,
    color,
    handleColorChange
  } = useImportSheet(buildMappedSheet, props.onSuccess, databaseRoot, keyMap as any);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files;
    if (files?.length !== 1) {
      throw Error('Cannot use multiple files');
    }

    const file = files?.[0];
    parseFile(file);
    setFileName(file.name);
  };

  const handleTextChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    parseString(event.currentTarget.value);
    setFileName(undefined);
  };

  return (
    <Container className='Importer-root'>
      <div>
        <ImporterForm
          rootInfo={rootInfo}
          handleTextChange={handleTextChange}
          isLoading={isLoading}
        />

        {result.keys && result.keys.length > 0 && (
          <ImporterColumnsMapper
            isLoading={isLoading}
            result={result}

            databaseRoot={databaseRoot}
            handleDatabaseRootChange={handleDatabaseRootChange}
            roots={roots}

            targetKeys={targetKeys}
            setKeyMapField={setKeyMapField}
            keyMap={keyMap}
            getTranslation={getTranslation}

            color={color}
            handleColorChange={handleColorChange}

            loadingProgress={loadingProgress}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </Container>
  );
};

export default Importer;

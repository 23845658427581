import React, {FunctionComponent, useMemo} from 'react';
import {
  ImportCustomerJobSuccessResult,
  MultipleChoiceCustomField,
  SingleChoiceCustomField
} from "../../firebase/api/ApiContext";
import {Alert} from "react-bootstrap";
import ExportCsvButton from "./ExportCsvButton";
import {Trans} from "react-i18next";
import {mapValues} from "lodash-es";

interface OwnProps {
  results: ImportCustomerJobSuccessResult
}

type Props = OwnProps;

const DataImported: FunctionComponent<Props> = (props) => {
  const {results} = props;

  useMemo(() => {
    return mapValues(results.root.customField, x => x.name);
  }, [results.root.customField])

  if (results.serverResponse.code === 'KO') {
    const customFieldElement = results.root.customField[results.serverResponse.param0!];
    const campo = customFieldElement?.name || results.serverResponse.param0;
    const opzione =
      (customFieldElement as MultipleChoiceCustomField | SingleChoiceCustomField)?.values?.[results.serverResponse.param1!]
      || results.serverResponse.param1;

    switch (results.serverResponse.err) {
      case 1011:
        return (
          <Alert variant='danger'>
            <Trans i18nKey='resultKO1011'>
              Il campo {{campo}} non è disponibile sul tuo database.
            </Trans>
          </Alert>
        )
      case 1012:
        return (
          <Alert variant='danger'>
            <Trans i18nKey='resultKO1012'>
              L’opzione {{opzione}} non è disponibile per il campo {{campo}}.
            </Trans>
          </Alert>
        )
      default:
        return (
          <Alert variant='danger'>
            <Trans i18nKey='resultKO'>
              Non sono riuscito ad importare i dati a causa di un errore del server.
            </Trans>
            <br/>
            <b>
              <Trans i18nKey='resultKOCode'>
                Codice errore: {{code: results.serverResponse.err}}
              </Trans>
            </b>
          </Alert>
        );
    }
  }

  const isPartialImport = results.errorsRows.length > 0;
  const totalRows = results.errorsRows.length + results.importedRows.length;
  const serverAddedResponse = results?.serverResponse.added;

  const boxes = [];

  boxes.push(
    <Alert key="success" variant='success'>
      <Trans i18nKey='resultSuccess' count={results.importedRows.length}>
        Ho importato correttamente {{count: results.importedRows.length}} su {{total: totalRows}} righe.
      </Trans>
    </Alert>
  );

  if (isPartialImport) {
    boxes.push(
      <Alert key="warning" variant='warning'>
        <Trans i18nKey='resultWarning' count={results.errorsRows.length}>
          Non sono riuscito ad importare {{count: results.errorsRows.length}} su {{total: totalRows}} righe.
        </Trans>
        <br/>
        <Trans i18nKey='resultWarningNext' count={results.errorsRows.length}>
          Di seguito è possibile scaricare un file CSV contenente le righe non importate.
        </Trans>
        <br/>
        <br/>
        <ExportCsvButton rows={results.errorsRows} variant="warning" size='lg'>
          <Trans i18nKey='resultWarningButton' count={results.errorsRows.length}>
            Scarica righe non importate
          </Trans>
        </ExportCsvButton>
      </Alert>
    )
  }

  if (serverAddedResponse !== results.importedRows.length) {
    boxes.push(
      <Alert key="danger" variant='danger'>
        <Trans i18nKey='resultDanger1'>
          Errore inaspettato! Il server ha importato un numero parziale di righe.
        </Trans>
        <br/>
        <Trans i18nKey='resultDanger2' count={results.importedRows.length}>
          Sono state inviate {{count: results.importedRows.length}} righe,
        </Trans>
        {' '}
        <Trans i18nKey='resultDanger3' count={serverAddedResponse}>
          ma il server ne ha importate solo {{count: serverAddedResponse}}.
        </Trans>
        <br/>
        <Trans i18nKey='resultDanger4'>
          Forse ci sono righe duplicate?
        </Trans>
      </Alert>
    )
  }

  if (results.outOfGeoCodingSlot) {
    boxes.push(
      <Alert key="outOfGeoCodingSlot" variant='danger'>
        <Trans i18nKey='resultOutOfGeoCodingSlot'>
          È stato richiesto di importare un numero di righe senza Latitudine/Longitudine superiore a quello consentito.
          <br/>
          Per favore, contattaci XXXXXXXXXXXXX
        </Trans>
      </Alert>
    )
  }

  return (
    <div>
      {boxes}
    </div>
  )
};

export default DataImported;
